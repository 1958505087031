/**
 * Get all data to display on dashboard for a single Project
 *
 * @param {object} gqlBlob - Object in format of
 *  {
 *      "privateProjectInfo": {},
 *      "projectUpdates": [{}],
 *      "publicProjectInfo": {},
 *  }
 *
 *  where projectUpdates is already sorted by completion date DESC
 */
export function getDataPerProject(gqlBlob) {
    if (!gqlBlob) {
        return {};
    }

    let { privateProjectInfo, projectUpdates, publicProjectInfo } = gqlBlob;

    if (!privateProjectInfo?.devTags) {
        privateProjectInfo = { devTags: {} };
    }
    privateProjectInfo = privateProjectInfo?.devTags || {};

    if (!publicProjectInfo?.devTags) {
        publicProjectInfo = { devTags: {} };
    }
    publicProjectInfo = publicProjectInfo?.devTags || {};

    if (!Array.isArray(projectUpdates) || projectUpdates.length <= 0 || !projectUpdates[0].devTags) {
        projectUpdates = [{ devTags: {} }];
    }

    let mostRecentProjectUpdateBeforeMovedQuestion = projectUpdates.find(projectUpdate => {
        if (!projectUpdate?.devTags) {
            return false;
        }

        delete projectUpdate.devTags.__typename;
        return Object.keys(projectUpdate.devTags).some(k => k !== 'burnRate' && !!projectUpdate.devTags[k]);
    });
    mostRecentProjectUpdateBeforeMovedQuestion = mostRecentProjectUpdateBeforeMovedQuestion?.devTags || {};

    const projectData = {};

    // these devTags always came from projectUpdates survey
    projectData.burnRate = projectUpdates[0].devTags.burnRate || '';
    projectData.mostRecentProjectUpdate = projectUpdates[0];

    // these devTags always came from privateProjectInfo survey
    projectData.projectedEndDate = privateProjectInfo.projectedEndDate || '';
    projectData.startDate = privateProjectInfo.startDate || '';
    projectData.projectManager = privateProjectInfo.projectManager || '';
    projectData.budget = privateProjectInfo.budget;
    projectData.contractualEndDate = privateProjectInfo.contractualEndDate;

    // these devTags used to be projectUpdates, but now they are privateProjectInfo
    projectData.completionRate =
        privateProjectInfo.completionRate || mostRecentProjectUpdateBeforeMovedQuestion.completionRate || '';
    projectData.isProjectFlagged =
        privateProjectInfo.isProjectFlagged || mostRecentProjectUpdateBeforeMovedQuestion.isProjectFlagged;
    projectData.timelineRating =
        privateProjectInfo.timelineRating || mostRecentProjectUpdateBeforeMovedQuestion.timelineRating || '';
    projectData.timelineNotes =
        privateProjectInfo.timelineNotes || mostRecentProjectUpdateBeforeMovedQuestion.timelineNotes || '';
    projectData.scopeRating =
        privateProjectInfo.scopeRating || mostRecentProjectUpdateBeforeMovedQuestion.scopeRating || '';
    projectData.scopeNotes =
        privateProjectInfo.scopeNotes || mostRecentProjectUpdateBeforeMovedQuestion.scopeNotes || '';
    projectData.moraleRating =
        privateProjectInfo.moraleRating || mostRecentProjectUpdateBeforeMovedQuestion.moraleRating || '';
    projectData.moraleNotes =
        privateProjectInfo.moraleNotes || mostRecentProjectUpdateBeforeMovedQuestion.moraleNotes || '';
    projectData.generalNotes =
        privateProjectInfo.generalNotes || mostRecentProjectUpdateBeforeMovedQuestion.generalNotes || '';
    projectData.weeksOverDate =
        privateProjectInfo.weeksOverDate || mostRecentProjectUpdateBeforeMovedQuestion.weeksOverDate;

    projectData.projectDescription =
        publicProjectInfo.projectDescription || privateProjectInfo.projectDescription || '';

    return projectData;
}
